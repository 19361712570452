@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

body {
  background-image: url("https://images.unsplash.com/photo-1528459801416-a9e53bbf4e17?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=824&q=80");
  text-align: center;
  font-family: "Montserrat", sans-serif;
}
.container {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1cap solid rgba(255, 255, 255, 0.03);
  width: 320px;
  height: 550px;
  border-radius: 0.5em;
  margin: 0 auto;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container .result {
  width: 100%;
  height: 150px;
}
.container .result p {
  font-size: 45pt;
  overflow-y: auto;
  text-align: right;
}

.container .keyboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1em;
}
.container .keyboard .button,
.container .buttons .num-button {
  cursor: grab;
  text-align: center;
  width: 60px;
  height: 60px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  border: 0.5px solid rgba(209, 205, 199, 0.5);
  box-shadow: 6px 6px 16px 0 rgba(209, 205, 199, 0.5),
    -6px -6px 16px 0 rgba(255, 255, 255, 0.5);
}

/*  UTILITIES*/
.container .keyboard .zero {
  grid-column: 1/ 3;
  width: 100%;
  border-radius: 10px;
  box-shadow: 6px 6px 16px 0 rgba(209, 205, 199, 0.5),
    -6px -6px 16px 0 rgba(255, 255, 255, 0.5);
}
.action-button {
  color: gray;
  font-weight: bold;
}
.calc-action-button {
  color: rgb(255, 94, 0);
  font-weight: bold;
}